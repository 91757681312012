import React, { useState, ChangeEvent } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Highlight } from "../components/Highlight";
import {
  Typography,
  Container,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
} from "@material-ui/core";
import { ContactCard } from "../components/ContactCard";
import { graphql } from "gatsby";
import SEO from "../components/seo";

type IndexProps = {
  data: any;
};

export default ({ data }: IndexProps) => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel: string) => (
    _: ChangeEvent,
    expanded: boolean
  ) => {
    setExpanded(expanded ? panel : "");
  };
  return (
    <Layout>
      <SEO title="Berufsträger" />
      <PageTitle fluidImage={data.file.childImageSharp.fluid}>
        <TransitionContainer direction="start">
          <Typography
            variant="h3"
            component="h1"
            paragraph
            style={{ marginTop: 80 }}
          >
            <br/> 
            <br/> 
            <br/> 
            <br/> 
            <br/>
            Der Blick auf das Ganze ist meine Stärke
          </Typography>
        </TransitionContainer>
      </PageTitle>
      <Container>
        <Box paddingTop={10} paddingBottom={10}>
          <Box marginBottom={3}>
            <Typography variant="h2" paragraph>
              Berufsträger
            </Typography>
          </Box>
          <Box marginBottom={6}>
            <Typography paragraph>
              Mehdi Tajdari ist bestellter Steuerberater, ehemaliger Steuerinspektor 
              sowie Bankkaufmann mit persischer Herkunft und ist
              als Experte auf dem Gebiet der nationalen und internationalen
              Steuern in Frankfurt am Main tätig.
            </Typography>
            <Typography paragraph>
              Vor seiner Tätigkeit als Syndikus-Steuerberater 
              war er bei der Unternehmensberatung Ernst & Young 
              in der Transaktionsberatung (M&A Tax), der Finanzverwaltung beim 
              Finanzamt Frankfurt am Main I und im Bankwesen bei der SEB AG im 
              Bereich der institutionellen Immobilienfinanzierung tätig.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" paragraph>
              Qualifikationen
            </Typography>
          </Box>
          <Box marginBottom={6}>
            <Typography paragraph>
              Steuerberater (Certified Tax Advisor)
            </Typography>
            <Typography paragraph>Diplom-Finanzwirt (FH)</Typography>
            <Typography paragraph>Bankkaufmann (IHK)</Typography>
          </Box>
          <Typography variant="h4" paragraph>
            Beruflicher Werdegang
          </Typography>
          <Box marginBottom={3}>
            <Typography paragraph>DZ BANK AG: seit Juli 2019</Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Syndikus-Steuerberater</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>
                      Erstellung von steuerlichen Stellungnahmen zu
                      Gesetzesvorhaben, Einzelfragen,
                      Finanzrechtsprechung oder neuen BMF- Schreiben bezüglich
                      deren Auswirkungen im Rahmen der Projektarbeit oder der
                      laufenden Beratung
                    </li>
                    <li>
                      Laufende steuerliche Beratung vorwiegend auf den Gebieten 
                      der Ertragsteuern, Umsatzsteuer, Gewerbesteuer und des internationalen
                      Steuerrechts{" "}
                    </li>
                    <li>Projektarbeit zu diversen Transparenzvorschriften </li>
                    <li> Verantwortlich für Themen wie u.a. DAC6, StAbwG </li>
                    <li> steuerliche Betreuung einer Organgesellschaft </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Box marginBottom={3}>
            <Typography paragraph>
              Ernst &amp; Young (EY): Okt. 2015 - Juni 2019
            </Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Consultant - Transaction Tax / M&amp;A Tax / Steuerberater
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>
                      Unterstützung im Bereich von nationalen und
                      internationalen Akquisitionen und Fusionen
                    </li>
                    <li>Tax Modeling und Analyse</li>
                    <li>Aufsetzen von Erwerbs- und Veräußerungsstrukturen</li>
                    <li>
                      Risikoanalyse von Steuerpositionen im Rahmen einer
                      Due-Diligence-Prüfung für Unternehmenskäufer und
                      -verkäufer
                    </li>
                    <li>
                      Bereitstellen von steuerlich relevanten Themen für die
                      finanzielle Ausgestaltung der Transaktion (sog. Financial
                      Model)
                    </li>
                    <li>
                      Post-closing Integration und steuerliche Optimierung
                    </li>
                    <li>
                      Steuerliche Beratung im Zusammenhang mit Refinanzierungen
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>

          <Box marginBottom={3}>
            <Typography paragraph>
              Finanzamt Frankfurt a.M. I: Juli 2014 - Sep. 2015
            </Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Steuerinspektor</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>
                      Bearbeiten von Einsprüchen und Selbstanzeigen, Klagen und
                      Revisionen vorbereiten
                    </li>
                    <li>
                      Zusammenarbeit mit der Straf- und Bußgeldsachenstelle,
                      Vollstreckungsstelle und der
                      Betriebsprüfung/Steuerfahndung
                    </li>
                    <li>
                      Anlernen der Anwärter
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>

          <Box marginBottom={3}>
            <Typography paragraph>SEB AG: Jan. 2011 - Juli 2011</Typography>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Junior Account Manager, institutionelle Immobilienkunden, Real
                  Estate Finance
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>
                      Analyse, Aufbereitung, Prüfung und Beurteilung von
                      Bonitäts- und Objektunterlagen
                    </li>
                      <li>
                      Einschätzung und
                      Überwachung von Kreditrisiken sowie Konzeption der
                      erforderlichen Maßnahmen
                    </li>
                    <li>
                      Unterscheidung und Bewertung von Sicherheiten und
                      Covenants und Mitwirkung bei der Bearbeitung von
                      Sicherungsverträgen
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Typography variant="h4" paragraph>
            Ausbildung
          </Typography>
          <Box marginBottom={3}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Hessisches Ministerium der Finanzen, Wiesbaden</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Steuerberaterexamen</li>
                    <li>Schwerpunkte: Steuerrecht, Wirtschaftsrecht</li>
                    <li>
                      Abschluss: Steuerberater (Steuerberaterkammer Hessen
                      K.d.ö.R.)
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Hessische Hochschule für Finanzen und Rechtspflege, Rotenburg
                  an der Fulda
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Schwerpunkte: Steuerrecht, Wirtschaftsrecht</li>
                    <li>Abschluss: Diplom-Finanzwirt (FH)</li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Ausbildung zum Bankkaufmann bei der SEB AG, Frankfurt am Main
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Schwerpunkt Institutionelles Kreditgeschäft</li>
                    <li>Abschluss: Bankkaufmann (IHK)</li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Zivildienst: Kinderladen Löwengrube, Frankfurt am Main</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Sozialpädagogischer Verein e.V., Frankfurt am Main</li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Franz-Böhm-Schule, Frankfurt am Main</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Schwerpunkt Wirtschaft und Verwaltung</li>
                    <li>Abschluss: Fachhochschulreife</li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Box>
            <Typography variant="h4" paragraph>
              Organisationen
            </Typography>
            <Typography paragraph>
              Steuerberaterkammer Hessen K.d.ö.R.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4" paragraph>
              Sprachen
            </Typography>
            <Typography paragraph>Deutsch, Englisch, Farsi</Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "mehdi-tajdari.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
